import { request, userDataGet } from "@/components-js/requestSrv";
import { MUTATION_TYPES } from "@/store/const";

export const mutations = {
  [MUTATION_TYPES.SET_USER]: async state => {
    state.user = await userDataGet();
  },
  [MUTATION_TYPES.SET_DESIGN_FLAG]: (state, payload) => {
    state.isNewDesign = payload;
  },
  [MUTATION_TYPES.SET_BREAK_POINT]: (state, payload) => {
    state.breakPoint = payload;
  },
  [MUTATION_TYPES.SET_MODAL_PAYLOAD]: (state, payload) => {
    state.modalPayload = payload;
  },
  [MUTATION_TYPES.SET_POINT_LIST]: async state => {
    state.pointList = await request("/api/point/list", "GET");
  },
  [MUTATION_TYPES.SET_JOB_LIST]: async state => {
    state.jobList = await request("/api/job/list", "GET");
  },
  [MUTATION_TYPES.SET_PEOPLE_LIST]: async state => {
    state.peopleList = await request("/api/report/peoplelist", "GET");
  },
};
