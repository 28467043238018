import { DEVICE_TYPES } from "@/lib/helpers/checkDevice";

export const state = () => ({
  user: null,
  isNewDesign: false,
  breakPoint: DEVICE_TYPES.DESKTOP,
  pointList: [],
  jobList: [],
  peopleList: [],
  modalPayload: {},
});
