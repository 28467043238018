export const MUTATION_TYPES = {
  SET_USER: "SET_USER",
  SET_DESIGN_FLAG: "SET_DESIGN_FLAG",
  SET_BREAK_POINT: "SET_BREAK_POINT",
  SET_POINT_LIST: "SET_POINT_LIST",
  SET_JOB_LIST: "SET_JOB_LIST",
  SET_PEOPLE_LIST: "SET_PEOPLE_LIST",
  SHOW_MODAL: "SHOW_MODAL",
  SET_MODAL_PAYLOAD: "SET_MODAL_PAYLOAD",
};

export const ACTION_TYPES = {
  LOGIN: "LOGIN",
  GET_BREAK_POINT: "GET_BREAK_POINT",
  GET_POINT_LIST: "GET_POINT_LIST",
  GET_JOB_LIST: "GET_JOB_LIST",
  GET_PEOPLE_LIST: "GET_PEOPLE_LIST",
  SHOW_MODAL: "SHOW_MODAL",
};
